export const ECOBEE_MAX_YEAR = 2035
export const ECOBEE_MAGIC_NUMBER = -5002
export const NEVER_ONLINE_STATUS_MODIFIED_DATE = '2010-01-01 01:01:01'
export const ENDASH = '\u2013'
export const EMDASH = '\u2014'
export const EMPTY_INDEX = -1
export const NAMESPACE = '__smartBuildings__'
export const NAME_VALIDATION_MESSAGE =
	'Enter a name under 12 alphanumeric characters'
export const NAME_DUPLICATE_VALIDATION_MESSAGE = 'This name is a duplicate.'
export const RESPONSE_VALIDATION_ERROR = 7

export const DEFAULT_MIN = 450
export const DEFAULT_MAX = 920

export const OCCUPIED_CLIMATE_REF = 'occupied'
export const OCCUPIED_DEFAULT_HEAT_INITIAL = 700
export const OCCUPIED_DEFAULT_COOL_INITIAL = 780

export const UNOCCUPIED_CLIMATE_REF = 'unoccupied'
export const UNOCCUPIED_DEFAULT_HEAT_INITIAL = 620
export const UNOCCUPIED_DEFAULT_COOL_INITIAL = 850

export const HOME_CLIMATE_REF = 'home'
export const HOME_DEFAULT_HEAT_INITIAL = 700
export const HOME_DEFAULT_COOL_INITIAL = 740

export const AWAY_CLIMATE_REF = 'away'
export const AWAY_DEFAULT_HEAT_INITIAL = 640
export const AWAY_DEFAULT_COOL_INITIAL = 820

export const SLEEP_CLIMATE_REF = 'sleep'
export const SLEEP_DEFAULT_HEAT_INITIAL = 620
export const SLEEP_DEFAULT_COOL_INITIAL = 820

export const AWAKE_CLIMATE_REF = 'wakeup'
export const AWAKE_DEFAULT_HEAT_INITIAL = 700
export const AWAKE_DEFAULT_COOL_INITIAL = 740

export const ONLINE = 'online'
export const OFFLINE = 'offline'

export const UNACKNOWLEDGED = 'unacknowledged'

export const DEFAULT_TEMPERATURE_MARGIN = 120
export const DEFAULT_TEMPERATURE_LIMIT = 1000
export const NOT_FOUND_INDEX = -1
export const RADIX = 10

// error codes
export const BUILDING_NOT_EMPTY_ERROR = 'building_not_empty'
export const DUPLICATE_BUILDING_NAME_ERROR = 'building_duplicate_name'
export const INVALID_BUILDING_NAME_ERROR = 'building_invalid_name'

export const COPY_FROM_COMFORT_SETTING = 'copyFromComfortSetting'
export const CUSTOMIZE_SETPOINTS = 'customizeSetpoints'
export const ALL_DAY_START_TIME_INDEX = 0
export const ALL_DAY_END_TIME_INDEX = 48

export const DEFAULT_START_TIME_INDEX = 18
export const DEFAULT_END_TIME_INDEX = 34

export const FAN_MODE_AUTO = 'auto'
export const HVAC_MODE_AUTO = 'auto'

export const ALERTS = 'alerts'
export const HVAC_MODE = 'hvac-mode'
export const TEMPORARY_HOLD = 'temporary-hold'
export const UPDATE_SENSOR = 'update-sensor'

export const SENSOR_STATUS_PARTICIPATING = 'participating'
export const SENSOR_STATUS_MONITOR = 'monitor'

export const CONTACT_SALES_URL =
	'https://www.ecobee.com/smartbuildings/contact-us/'
export const CONTACT_SUPPORT_URL = 'https://www.ecobee.com/en-ca/get-help/'

export const CHARGEBEE_CHANNEL = 'chargebee'
export const BING_CHANNEL = 'bing'

export const VALIDATE_EMAIL_STEP_FORM = 'form'
export const VALIDATE_EMAIL_STEP_VERIFY = 'verify'
export const VALIDATE_EMAIL_STEP_SUCCESS = 'success'
export const VALIDATE_EMAIL_STEP_LOADING = 'loading'
export const VALIDATE_EMAIL_STEP_EXPIRED = 'expired'
export const VALIDATION_TOKEN_REGISTER_TYPE = 'REGISTER'
export const VALIDATION_TOKEN_RESET_TYPE = 'RESET'

export const INVITATION_STEP_NAME_FORM = 'name-form'
export const INVITATION_STEP_PASSWORD_FORM = 'password-form'
export const INVITATION_STEP_LOADING = 'loading'
export const INVITATION_STEP_EXPIRED = 'expired'
export const INVITATION_STEP_ERROR = 'error'

// internal error codes
export const SERVER_ERROR = 'server_error'
export const OAUTH_VALIDATION_TOKEN_EXPIRED_ERROR_CODE =
	'oauth_validation_token_expired'

export const HTTP_TOO_MANY_REQUESTS = 429

export const MIN_PASSWORD_LENGTH = 8

export const DEFAULT_COUNTRY = 'US'

export const TOKEN_KEY = 'token'
export const LOCALE_KEY = 'locale'
export const COUNTRY_KEY = 'country'

export const ACCOUNT_ROUTE = '/account'
export const BASE_ROUTE = '/'
export const FORGOT_ROUTE = '/forgot'
export const INVITATION_ROUTE = '/invitation'
export const LOGOUT_ROUTE = '/logout'

// events
export const DEMAND_RESPONSE_EVENT_TYPE = 'demandResponse'
export const HOLD_EVENT_TYPE = 'hold'
export const VACATION_EVENT_TYPE = 'vacation'
export const SMART_HOME_EVENT_TYPE = 'autoHome'
export const SMART_AWAY_EVENT_TYPE = 'autoAway'

export const DEMAND_RESPONSE_VOLUNTARY = 'demandResponseVoluntary'
export const DEMAND_RESPONSE_MANDATORY = 'demandResponseMandatory'

// filters
export const CONNECTION_FILTER = 'connection'
export const SET_MODE_FILTER = 'set-mode'
export const PROGRAM_FILTER = 'program'
export const TAGS_FILTER = 'tags'

// alert settings
export const HIGH_TEMP_THRESHOLD_MAX = 1040
export const HIGH_TEMP_THRESHOLD_MIN = 600
export const LOW_HUMIDITY_THRESHOLD_MIN = 6
export const LOW_HUMIDITY_THRESHOLD_MAX = 94
export const HIGH_HUMIDITY_THRESHOLD_MIN = 6
export const HIGH_HUMIDITY_THRESHOLD_MAX = 94
export const LOW_TEMP_THRESHOLD_MAX = 680
export const LOW_TEMP_THRESHOLD_MIN = 350

export const SECONDS_PER_MINUTE = 60

export const MAX_THERMOSTAT_DISPLAY_COUNT = 999

import { takeLatest, call, put } from 'redux-saga/effects'
import { loginRequest } from '@/store/login/actions'
import { login } from '@/store/login/sagas'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import errorLoggingService from '@/services/error.logging.service'
import history from '@/services/history'
import * as invitationAcceptActions from './actions'
import { INVITATION_ACCEPT_REQUEST } from './constants'

/**
 * @param action
 */
export function* invitationAccept(action) {
	const { email, firstName, lastName, password, token } = action.payload
	const payload = {
		firstName,
		lastName,
		password,
	}
	const apiResponse = yield call(safeFetch, 'acceptInvite', {
		token,
		payload,
	})
	const { response } = apiResponse

	if (response.ok) {
		yield call(login, loginRequest({ username: email, password }))
		yield call([history, 'replace'], { pathname: '/' })
		yield put(invitationAcceptActions.invitationAcceptSuccess())
	} else {
		errorLoggingService.notify('Cannot create account')
		yield put(invitationAcceptActions.invitationAcceptError())
	}
}

/**
 *
 */
export function* invitationAcceptRequestWatcher() {
	yield takeLatest(INVITATION_ACCEPT_REQUEST, invitationAccept)
}

import AcceptUserInvitationRoute from './accept-user-invitation'
import AccountRoute from './account'
import BuildingListRoute from './building-list'
import BuildingRoute from './building'
import BuildingSettingsRoute from './building-settings'
import CompanyListRoute from './company-list'
import CompanyRoute from './company'
import CompanySettingsRoute from './company-settings'
import CompanyThermostatListRoute from './company-thermostat-list'
import CompanyThermostatRoute from './company-thermostat'
import CompanyUserSettingsRoute from './company-user-settings'
import CompanyUsersRoute from './company-users'
import ForgotPasswordRoute from './forgot-password'
import LegacyUrlRedirectRoute from './legacy-url-redirect'
import LoginRoute from './login'
import LogoutRoute from './logout'
import ManageSubscriptionsRoute from './manage-subscriptions'
import NotFoundRoute from './not-found'
import ResetPasswordRoute from './reset-password'
import SubscriptionRoute from './subscription'
import TermsAndPoliciesRoute from './terms-and-policies'
import ThermostatListRoute from './thermostat-list'
import ThermostatRoute from './thermostat'
import UserGuideRoute from './user-guide'
import ValidateEmailRoute from './validate-email'

const routes = [
	{
		pattern: '/subscribe',
		exact: true,
		strict: true,
		component: SubscriptionRoute,
		authenticated: false,
	},
	{
		pattern: '/terms-and-policies',
		exact: false,
		authenticated: true,
		component: TermsAndPoliciesRoute,
	},
	{
		pattern: '/terms-and-policies',
		exact: false,
		authenticated: false,
		component: TermsAndPoliciesRoute,
	},
	{
		pattern: '/user-guide',
		exact: false,
		authenticated: true,
		component: UserGuideRoute,
	},
	{
		pattern: '/user-guide',
		exact: false,
		authenticated: false,
		component: UserGuideRoute,
	},
	{
		pattern: '/',
		exact: true,
		component: CompanyListRoute,
		authenticated: true,
	},
	// Legacy URL Redirect START
	{
		pattern: '/building',
		exact: true,
		strict: true,
		component: LegacyUrlRedirectRoute,
		authenticated: true,
	},
	{
		pattern: '/building/:id+',
		exact: true,
		strict: true,
		component: LegacyUrlRedirectRoute,
		authenticated: true,
	},
	// Legacy URL Redirect END
	{
		pattern: '/account',
		exact: false,
		component: AccountRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/account',
		exact: false,
		component: AccountRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/settings',
		exact: true,
		strict: true,
		component: CompanySettingsRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/settings/subscriptions',
		exact: true,
		strict: true,
		component: ManageSubscriptionsRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/users',
		exact: true,
		strict: true,
		component: CompanyUsersRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/users/:userId',
		exact: true,
		strict: true,
		component: CompanyUserSettingsRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/building',
		exact: true,
		strict: true,
		component: BuildingListRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/building/:buildingId',
		exact: true,
		strict: true,
		component: BuildingRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/building/:buildingId/settings',
		exact: true,
		strict: true,
		component: BuildingSettingsRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/building/:buildingId/thermostat',
		exact: true,
		strict: true,
		component: ThermostatListRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/building/:buildingId/thermostat/:thermostatId',
		exact: false,
		component: ThermostatRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/thermostat',
		exact: true,
		strict: true,
		component: CompanyThermostatListRoute,
		authenticated: true,
	},
	{
		pattern: '/:companyId/thermostat/:thermostatId',
		exact: false,
		component: CompanyThermostatRoute,
		authenticated: true,
	},
	{
		pattern: '/logout',
		exact: true,
		strict: true,
		component: LogoutRoute,
		authenticated: true,
	},
	{
		pattern: '/reset',
		exact: true,
		strict: true,
		component: ResetPasswordRoute,
		authenticated: false,
	},
	{
		pattern: '/validate',
		exact: true,
		strict: true,
		component: ValidateEmailRoute,
		authenticated: false,
	},
	{
		pattern: '/program/:programId',
		exact: true,
		strict: true,
		component: ValidateEmailRoute,
		authenticated: false,
	},
	{
		pattern: '/forgot',
		exact: true,
		strict: true,
		component: ForgotPasswordRoute,
		authenticated: false,
	},
	{
		pattern: '/invitation',
		exact: true,
		strict: true,
		component: AcceptUserInvitationRoute,
		authenticated: false,
	},
	{
		pattern: '/:companyId',
		exact: true,
		strict: true,
		component: CompanyRoute,
		authenticated: true,
	},
	{
		pattern: '',
		component: NotFoundRoute,
		authenticated: true,
	},
	{
		pattern: '/',
		exact: false,
		component: LoginRoute,
		authenticated: false,
	},
]

export default routes
